import React from "react";
import { DeveloperTour } from "../../components/DeveloperTour";
import { PageLayout } from "../../components/PageLayout";
import { developerTourPageHeroImage } from "../../images";

const DeveloperTourPage: React.FC = () => {
  return (
    <PageLayout
      heroBackgroundImage={`url(${developerTourPageHeroImage})`}
      heroOverlayLeadElement="The Developer Experience"
      heroOverlaySecondaryElement="A guided tour from the developer's perspective"
    >
      <DeveloperTour />
    </PageLayout>
  );
};

export default DeveloperTourPage;
